import { FooterLink } from './footerLink'
import { PRODUCT_LINKS, ENTERPRISE_LINKS, LEGAL_LINKS } from './constants'
import { Link, TooltipOnHover } from 'components/ui'

export function Footer() {
    return (
        <div className='px-[var(--p-page-sides)]'>
            <div className='container'>
                <div className='flex flex-col w-full py-6 border-t-2 border-solid border-black/5 dark:border-white/15'>
                    <div className='flex flex-wrap justify-between gap-4'>
                        <div className='flex flex-col items-start gap-4 md:flex-row md:items-center'>
                            <Link href='/'>
                                <div className='flex items-center w-10 h-5'>
                                    <span className='icon icon-sr [--size:40px] [--color:black] dark:[--color:--c-lightest]' />
                                </div>
                            </Link>
                            {PRODUCT_LINKS.map(({ title, link, blank }) => (
                                <FooterLink
                                    key={link}
                                    title={title}
                                    link={link}
                                    blank={blank}
                                />
                            ))}
                            <TooltipOnHover
                                text={
                                    <div className='flex flex-col gap-4 text-sm'>
                                        {ENTERPRISE_LINKS.map(
                                            ({ title, link, blank }) => (
                                                <FooterLink
                                                    key={link}
                                                    title={title}
                                                    link={link}
                                                    blank={blank}
                                                />
                                            )
                                        )}
                                    </div>
                                }
                            >
                                <span className='text-contrast-3 cursor-pointer'>
                                    Enterprise
                                </span>
                            </TooltipOnHover>
                        </div>
                        <div className='flex flex-col justify-end items-end gap-4 md:flex-row md:items-center'>
                            {LEGAL_LINKS.map(({ title, link, blank }) => (
                                <FooterLink
                                    key={link}
                                    title={title}
                                    link={link}
                                    blank={blank}
                                />
                            ))}
                            <span className='text-contrast-3'>{`${new Date().getFullYear()}©Staking Rewards.`}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

import numeral from 'numeral'
import {
    NO_DATA_INDICATOR,
    capitalizeAll,
    getPrecisionBySignificantFigures,
    isInvalidNumber,
} from 'utils/formatter'
import {
    ASSETS_UNDER_MANAGEMENT_METRIC_KEY,
    CUSTOM_AUM_CHANGE_METRIC_KEY,
    CUSTOM_NET_STAKING_FLOW_7D_METRIC_KEY,
    DEFAULT_ASSET_TIMEFRAME,
    PageSizes,
} from 'utils/constants'

export const baseUrl =
    process.env.NEXT_PUBLIC_VERCEL_ENV === 'development'
        ? 'http://localhost:3000'
        : process.env.NEXT_PUBLIC_VERCEL_ENV !== 'production'
          ? 'https://staging.stakingrewards.com'
          : 'https://www.stakingrewards.com'

export const SEOManagerApi = process.env.NEXT_PUBLIC_SEO_MANAGER_URL ?? ''

export async function fetchApi(url, data, method = 'POST') {
    const response = await fetch(url, {
        method: method,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    })

    const { error } = await response.json()

    if (error) {
        return { error: true, message: error }
    }

    return { success: true, response: response }
}

export function validateEmail(email) {
    // https://stackoverflow.com/a/201378/3959503
    return /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(
        email
    )
}

const htmlEntities = {
    nbsp: ' ',
    cent: '¢',
    pound: '£',
    yen: '¥',
    euro: '€',
    copy: '©',
    reg: '®',
    lt: '<',
    gt: '>',
    quot: '"',
    amp: '&',
    apos: "'",
}

function unescapeHTML(str) {
    return str.replace(/&([^;]+);/g, function (entity, entityCode) {
        let match

        if (entityCode in htmlEntities) {
            return htmlEntities[entityCode]
            /*eslint no-cond-assign: 0*/
        } else if ((match = entityCode.match(/^#x([\da-fA-F]+)$/))) {
            return String.fromCharCode(parseInt(match[1], 16))
            /*eslint no-cond-assign: 0*/
        } else if ((match = entityCode.match(/^#(\d+)$/))) {
            return String.fromCharCode(~~match[1])
        } else {
            return entity
        }
    })
}

export function trimHtml(html) {
    return html
        .replace(/&nbsp;/g, ' ')
        .replace(/<[^\/>][^>]*><\/[^>]+>/g, '')
        .replace(/^(\s*<br\s*\/?>)*\s*|\s*(<br\s*\/?>\s*)*$/g, '')
        .trim()
}

export function trimUnescapedHtml(string) {
    const str = string.replace(/(<([^>]+)>)/gi, '')
    return unescapeHTML(str)
}

export const decodeHtmlEntities = str => {
    return str.replace(/&#(\d+);/gu, (match, dec) => {
        return String.fromCharCode(dec)
    })
}

export const classes = (arr = []) => arr.join(' ')

export const mapRange = (value, inputStart, inputEnd) =>
    ((value - inputStart) * (1 - inputEnd)) / (0 - inputStart) + inputEnd

export const logState = state => console.log(JSON.parse(JSON.stringify(state)))

export const makeObject = (arr = [], key = 'metricKey') =>
    arr.reduce((t, c) => ({ ...t, [c[key]]: c }), {})

export const makeMillions = money => numeral(money).format('$0.00a')

// NB: import the component dynamically to use
export const getMaxWidthOfString = (str = '', font = '') => {
    const tempElement = document.createElement('span')
    tempElement.textContent = str
    tempElement.style.font = font
    tempElement.style.position = 'absolute'
    tempElement.style.visibility = 'hidden'
    document.body.appendChild(tempElement)
    const maxWidth = tempElement.getBoundingClientRect().width
    document.body.removeChild(tempElement)
    return maxWidth
}

export const getMetricObjectByKey = (item = null, metricKey = '') =>
    item?.metrics?.find(metric => metric?.metricKey === metricKey)

export const getMetricDefaultValueByKey = (item = null, metricKey = '') =>
    item?.metrics?.find(metric => metric?.metricKey === metricKey)?.defaultValue

// NB: The custom logic is defined in https://airtable.com/appQTksBuCtRwA3F9/tblKtjbIZiR19ZkF1/viwPvEmkU0PeSfAIg
export const getMetricValueByKey = (
    item = null,
    metricKey = '',
    timeframeKey = ''
) => {
    if (metricKey === 'price' && timeframeKey) {
        const price = getMetricChangePercentage(
            item?.metrics ?? [],
            'price',
            timeframeKey
        )
        return price
    }

    if (metricKey === 'performance') {
        const value = getMetricDefaultValueByKey(item, metricKey)
        if (value === 0) {
            return undefined
        }
    }

    if (metricKey === 'reward_stability_365d') {
        const value = getMetricDefaultValueByKey(item, metricKey)
        if (!isInvalidNumber(value)) {
            return Math.min(value * 100, 100)
        }
    }

    if (metricKey === 'trading_volume_trend_24h') {
        const value = getMetricDefaultValueByKey(item, metricKey)
        if (!isInvalidNumber(value)) {
            return value - 100
        }
    }

    if (metricKey === 'price_roi_365d') {
        return getMetricChangePercentage(item?.metrics, 'price', '1y')
    }

    if (metricKey === 'total_roi_365d') {
        const priceRoi = getMetricChangePercentage(item?.metrics, 'price', '1y')
        const stakingRoi = getMetricDefaultValueByKey(item, `staking_roi_365d`)

        return calculateIfValid(
            ({ priceRoi, stakingRoi }) =>
                100 * ((1 + priceRoi / 100) * (1 + stakingRoi / 100) - 1),
            {
                priceRoi,
                stakingRoi,
            }
        )
    }

    // Fractions to percentages
    if (['staking_share', 'peg_deviation'].includes(metricKey)) {
        const value = getMetricDefaultValueByKey(item, metricKey)
        return calculateIfValid(({ value }) => value * 100, {
            value,
        })
    }

    if (metricKey === 'peg_stability') {
        const value = getMetricDefaultValueByKey(item, 'price')
        return calculateIfValid(({ value }) => value * 100, {
            value,
        })
    }

    if (metricKey === CUSTOM_AUM_CHANGE_METRIC_KEY) {
        return getMetricAbsoluteChange(
            item?.metrics ?? [],
            ASSETS_UNDER_MANAGEMENT_METRIC_KEY,
            timeframeKey
        )
    }

    if (metricKey === CUSTOM_NET_STAKING_FLOW_7D_METRIC_KEY) {
        const price = getMetricDefaultValueByKey(item, 'price')
        const stakedTokensChange = getMetricAbsoluteChange(
            item?.metrics ?? [],
            'staked_tokens',
            '7d'
        )

        return calculateIfValid(
            ({ price, stakedTokensChange }) => price * stakedTokensChange,
            {
                price,
                stakedTokensChange,
            }
        )
    }

    if (metricKey === 'net_staking_flow' && timeframeKey) {
        const netStakingFlow = getObjectFromJsonString(
            item?.metrics?.find(
                metric => metric?.metricKey === 'net_staking_flow'
            )?.variation ?? ''
        )?.[timeframeKey]
        return isInvalidNumber(netStakingFlow)
            ? getMetricDefaultValueByKey(
                  item,
                  `net_staking_flow_${timeframeKey}`
              )
            : netStakingFlow
    }

    return getMetricDefaultValueByKey(item, metricKey)
}

export const isMetricWithExpansion = (metricKey = '') => {
    return ![
        CUSTOM_NET_STAKING_FLOW_7D_METRIC_KEY,
        CUSTOM_AUM_CHANGE_METRIC_KEY,
        'peg_stability',
        'total_roi_365d',
        'price_roi_365d',
        'performance',
    ].includes(metricKey)
}

export const getObjectFromJsonString = (jsonStr = '') => {
    let obj
    try {
        obj = JSON.parse(jsonStr)
    } catch (error) {
        // console.error('Error parsing JSON string:', error.message)
    }
    return obj
}

export const getMetricChangePercentage = (
    metrics = [],
    metricKey = '',
    timeframe = DEFAULT_ASSET_TIMEFRAME.key
) => {
    return getObjectFromJsonString(
        metrics?.find(metric => metric?.metricKey === metricKey)
            ?.changePercentages
    )?.[timeframe]
}

export const getMetricAbsoluteChange = (
    metrics = [],
    metricKey = '',
    timeframe = DEFAULT_ASSET_TIMEFRAME.key
) => {
    return getObjectFromJsonString(
        metrics?.find(metric => metric?.metricKey === metricKey)
            ?.changeAbsolutes
    )?.[timeframe]
}

export const getMetricPercentage = (
    item = null,
    metricKey = '',
    timeframeKey = DEFAULT_ASSET_TIMEFRAME.key,
    unit = ''
) => {
    return unit === '%'
        ? getMetricAbsoluteChange(item?.metrics, metricKey, timeframeKey)
        : getMetricChangePercentage(item?.metrics, metricKey, timeframeKey)
}

export const getMetricPrecision = (metricKey = '', value = null) => {
    let precision = 2
    if (metricKey === 'price') {
        precision = Math.max(getPrecisionBySignificantFigures(value, 3), 2)
    } else if (metricKey === 'lockup_time') {
        precision = 0
    } else if (metricKey === 'staking_share') {
        if (isInvalidNumber(value) || value === 0) {
            precision = 0
        } else {
            const number = Math.abs(value)
            const power = Math.floor(Math.log10(Math.abs(number))) + 1
            // precision needed for 3 significant figures:
            precision = Math.max(0, 3 - power)
        }
    }

    return precision
}

export const calculateIfValid = (func = () => {}, params = {}) => {
    try {
        const allParamsValid = Boolean(
            Object.values(params)?.every(param => !isInvalidNumber(param))
        )
        if (!allParamsValid) {
            throw new Error('Invalid params')
        }
        const result = func(params)
        if (isInvalidNumber(result)) {
            throw new Error('NaN calculation result')
        }
        return result
    } catch (error) {
        return NO_DATA_INDICATOR
    }
}

export const clipboardCopy = async (text = '') => {
    try {
        // NB: Requires a secure browsing context (https)
        if (!navigator.clipboard) {
            throw new Error('Copying is not supported by this browser')
        }
        await navigator.clipboard.writeText(text)
        return 'Copied'
    } catch (err) {
        console.error(err)
    }
    return 'Error'
}

export const readTime = text => {
    const contentStripped = String(text).replace(/<(.|\n)*?>/gu, '')
    const content = contentStripped.split(' ')

    const wps = 3.33
    const wordCount = content.length
    const totalSeconds = wordCount / wps
    const totalMinutes = totalSeconds / 60

    return `${Math.ceil(totalMinutes)} Minute Read`
}

export const getTagName = (tagKey = '') => {
    if (tagKey === 'proof-of-stake' || tagKey === 'pos') {
        return 'Proof of Stake'
    }

    if (tagKey === 'smart-contract') {
        return 'Smart Contract Staking'
    }

    return capitalizeAll(String(tagKey ?? '').replaceAll('-', ' '))
}

export const getPageSizeOption = (pageSizeNumberKey = PageSizes.Twenty) => {
    return {
        key: pageSizeNumberKey,
        name: String(pageSizeNumberKey),
    }
}

export function getDaysBetweenDates(date1, date2) {
    // Calculate the difference in milliseconds
    const differenceInMilliseconds = Math.abs(date2 - date1)

    // Convert milliseconds to days and round down to nearest integer
    const differenceInDays = Math.floor(
        differenceInMilliseconds / (1000 * 60 * 60 * 24)
    )

    return differenceInDays
}

export const scrollIntoView = (element = null) => {
    if (element) {
        element.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'nearest',
        })
    }
}

import { createContext, useState, useContext, useEffect } from 'react'
import { useStorageState, setStorageState } from 'state/storage'
import { useMediaPredicate } from 'react-media-hook'

const NavigationContext = createContext({
    isDesktopNavbarOpen: true,
    breadcrumbTitle: null,
    overlayScreen: null,
    setIsDesktopNavbarOpen: () => {},
    setBreadcrumbTitle: () => {},
    setOverlayScreen: () => {},
})

export function NavigationContextProvider({ children }) {
    const isDesktopNavbarOpenFromStorage = useStorageState(
        'isDesktopNavbarOpen'
    )
    const { setIsDesktopNavbarOpen } = setStorageState()
    const [breadcrumbTitle, setBreadcrumbTitle] = useState(null)
    const [overlayScreen, setOverlayScreen] = useState(null)

    const isTablet = useMediaPredicate('(min-width: 768px)')

    // if isDesktopNavbarOpenFromStorage is null, then we need to set it to true if the screen width is greater than tablet
    const isDesktopNavbarOpen = isDesktopNavbarOpenFromStorage ?? isTablet

    return (
        <NavigationContext.Provider
            value={{
                isDesktopNavbarOpen,
                breadcrumbTitle,
                overlayScreen,
                setIsDesktopNavbarOpen,
                setBreadcrumbTitle,
                setOverlayScreen,
            }}
        >
            {children}
        </NavigationContext.Provider>
    )
}

export function useNavigationContext() {
    return useContext(NavigationContext)
}

export function useSetBreadcrumbTitle(title) {
    const { setBreadcrumbTitle } = useNavigationContext()

    useEffect(() => {
        if (title) {
            setBreadcrumbTitle(title)
        }

        return () => {
            setBreadcrumbTitle(null)
        }
    }, [title, setBreadcrumbTitle])
}

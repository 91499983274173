import { useTheme, setStorageState, THEME } from 'state'
import { NavBarItem } from './navbarItem'

export function ThemeNavbarItem() {
    const theme = useTheme()
    const { setTheme } = setStorageState()
    const isThemeDark = theme === THEME.DARK

    return (
        <NavBarItem
            title={isThemeDark ? 'Light' : 'Dark'}
            iconClassName={isThemeDark ? 'icon-sun' : 'icon-moon'}
            onClick={() => setTheme(isThemeDark ? THEME.LIGHT : THEME.DARK)}
        />
    )
}

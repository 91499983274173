import classNames from 'classnames'
import { useRouter } from 'next/router'
import { useMediaPredicate } from 'react-media-hook'
import { Link } from 'components/ui'
import { useNavigationContext } from 'contexts/navigationContext'

export function NavBarItem({
    iconClassName = null,
    title = '',
    link = '',
    onClick = () => {},
    activeLinks = [],
}) {
    const router = useRouter()
    const { isDesktopNavbarOpen, setOverlayScreen } = useNavigationContext()
    const isTablet = useMediaPredicate('(min-width: 768px)')

    const preparedActiveURLs = [link, ...activeLinks]
    const isActive =
        link && preparedActiveURLs.some(url => router.asPath.startsWith(url))

    const handleClick = () => {
        setOverlayScreen(null)
        onClick()
    }

    const content = (
        <div
            data-tooltip-id={'navbar-item-tooltip'}
            data-tooltip-content={title}
            className={classNames(
                'flex gap-4 justify-start items-center px-6 py-4 text-contrast-3',
                'border-r-2 border-solid border-transparent',
                'transition-colors',
                'hover:bg-blue/5 hover:text-contrast-4 dark:hover:bg-primary-10',
                isActive &&
                    '!text-blue !border-blue dark:!text-cyan dark:!border-cyan'
            )}
        >
            <div className='w-7 h-7 md:w-[18px] md:h-[18px]'>
                <span
                    className={classNames(
                        'icon [--size:28px] md:[--size:18px]',
                        '[--color:currentColor]',
                        iconClassName
                    )}
                />
            </div>
            <span
                className={classNames(
                    'text-2xl md:text-base',
                    'transition-opacity',
                    isTablet && !isDesktopNavbarOpen
                        ? 'opacity-0'
                        : 'opacity-100'
                )}
            >
                {title}
            </span>
        </div>
    )

    if (link) {
        return (
            <Link className='w-full' href={link} onClick={handleClick}>
                {content}
            </Link>
        )
    }

    return (
        <div className='w-full cursor-pointer' onClick={handleClick}>
            {content}
        </div>
    )
}

import classNames from 'classnames'
import { useRouter } from 'next/router'
import { useState } from 'react'

import { useScrollYParams } from 'hooks/useScrollYParams'
import { THEME, useTheme } from 'state/storage'
import { Initialize } from 'components/ui/initialize'
import { Overlay } from 'components/ui/overlay'
import {
    AnnouncementsContextProvider,
    AnnouncementBar,
} from 'components/announcements'
import { Navbar } from 'components/navbar'
import { Header } from 'components/header'
import { CookieBanner } from './cookieBanner'
import { FloatingBannerProvider } from 'contexts/floatingBannerContext'
import { AnnouncementBarProvider } from 'contexts/announcementBarContext'
import { NavigationContextProvider } from 'contexts/navigationContext'
import { ContentContainer } from 'components/contentContainer'
import { useRouterPages } from 'components/navbar/hooks'

import styles from './ui.module.scss'

export function Ui({ children }) {
    const theme = useTheme()
    const router = useRouter()

    const { isStakeAppPage, isSwapAppPage, isPortfolioDashboard } =
        useRouterPages()

    const [isShowAnnouncementBar, setIsShowAnnouncementBar] = useState(true)

    useScrollYParams()

    const isBrandedStakeApp = isStakeAppPage && router?.query?.locked === 'true'
    const isBrandedSwapApp =
        isSwapAppPage && router?.query?.outputlocked === 'true'

    const isBrandedPage = isBrandedStakeApp || isBrandedSwapApp

    return (
        <FloatingBannerProvider>
            <AnnouncementBarProvider>
                <div
                    id='ui'
                    className={classNames(styles.ui, {
                        [styles.srEthBg]:
                            String(router.asPath).startsWith('/sreth') ||
                            String(router.asPath).startsWith('/404'),
                        ['bg-[linear-gradient(to_bottom,#002195,#02A4FF75)]']:
                            (isStakeAppPage || isSwapAppPage) &&
                            theme === THEME.DARK,
                        'bg-[linear-gradient(to_bottom,var(--c-wb),#FEA73F00,#9C1BFA33,#02A4FF50)]':
                            (isStakeAppPage || isSwapAppPage) &&
                            theme === THEME.LIGHT &&
                            !isBrandedPage,
                        'bg-wb': isPortfolioDashboard && theme === THEME.DARK,
                    })}
                    data-theme={theme}
                >
                    <Initialize />
                    <NavigationContextProvider>
                        <AnnouncementsContextProvider>
                            <div className='flex flex-col w-full h-screen'>
                                {isShowAnnouncementBar ? (
                                    <AnnouncementBar
                                        setIsShow={setIsShowAnnouncementBar}
                                    />
                                ) : null}
                                <div className='flex w-full h-full overflow-x-hidden overflow-y-auto'>
                                    <Navbar />
                                    <div className='flex flex-col w-full overflow-x-hidden overflow-y-auto'>
                                        <Overlay />
                                        <Header />
                                        <ContentContainer>
                                            {children}
                                        </ContentContainer>
                                    </div>
                                </div>
                            </div>
                        </AnnouncementsContextProvider>
                    </NavigationContextProvider>
                    <CookieBanner />
                </div>
            </AnnouncementBarProvider>
        </FloatingBannerProvider>
    )
}
